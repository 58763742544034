define('lfrz-login/pods/login/register/confirm/index/route', ['exports', 'ember', 'lfrz-login/helpers/ApiHelper'], function (exports, _ember, _lfrzLoginHelpersApiHelper) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model(param) {
            return _lfrzLoginHelpersApiHelper['default'].Get('Register/' + param.id).then(function (data) {
                data = data || {};
                data.id = param.id;
                return _ember['default'].Object.create(data);
            });
        }
    });
});