define('lfrz-login/pods/login/register/datenübernahme/route', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model(params, transition) {
            if (transition.queryParams.betriebsnummer) {
                return _ember['default'].Object.create({
                    betriebsnummer: transition.queryParams.betriebsnummer
                });
            }
        },
        afterModel: function afterModel(model) {
            if (!model) {
                this.transitionTo('login.register.index');
            }
        }
    });
});