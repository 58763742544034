define('lfrz-login/pods/login/register/selbstregistrierung/route', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model(params, transition) {
            if (transition.queryParams.wrapdata) {
                return _ember['default'].Object.create({
                    wrapdata: transition.queryParams.wrapdata,
                    email: transition.queryParams.wrapdata.email,
                    firstname: transition.queryParams.wrapdata.firstname,
                    lastname: transition.queryParams.wrapdata.lastname
                });
            } else {
                return _ember['default'].Object.create({});
            }
        }
    });
});