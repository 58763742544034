define('lfrz-login/pods/protected/admin/statistic/route', ['exports', 'ember', 'lfrz-login/helpers/ApiHelper', 'lfrz-login/modules/GlobalObjects'], function (exports, _ember, _lfrzLoginHelpersApiHelper, _lfrzLoginModulesGlobalObjects) {
    /* global google */
    exports['default'] = _ember['default'].Route.extend({
        model: function model() {
            return _lfrzLoginHelpersApiHelper['default'].Get('AdminStatistic').then(function (data) {
                //success
                return data;
            }, function () {
                //fail
                return;
            });
        },
        afterModel: function afterModel() {
            if (!_lfrzLoginModulesGlobalObjects['default'].get('loadedChartLibrary')) {
                _lfrzLoginModulesGlobalObjects['default'].set('loadedChartLibrary', true);
                return _ember['default'].$.getScript('https://www.gstatic.com/charts/loader.js').then(function () {
                    var deferred = _ember['default'].$.Deferred();

                    google.charts.load('current', { 'packages': ['corechart'], 'language': 'de' });
                    google.charts.setOnLoadCallback(function () {
                        deferred.resolve();
                    });

                    return deferred.promise();
                });
            }
        }
    });
});