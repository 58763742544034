define('lfrz-login/pods/protected/route', ['exports', 'ember', 'lfrz-login/modules/GlobalObjects'], function (exports, _ember, _lfrzLoginModulesGlobalObjects) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model() {
            var token = _ember['default'].$.localStorage.get('authToken') || { firstName: '', lastName: '', betriebId: '' };
            var obj = {
                firstname: token.firstName,
                lastname: token.lastName,
                betriebid: token.betriebId,
                isAdmin: token.isAdmin && token.isAdmin >= 1,
                isMaster: token.isMaster && token.isMaster >= 1
            };

            if (token.pendingRegistrations) {
                _lfrzLoginModulesGlobalObjects['default'].set('pendingRegistrations', parseInt(token.pendingRegistrations));
            }

            return obj;
        }
    });
});