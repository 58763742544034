define('lfrz-login/pods/components/input-generic/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        type: 'text',
        classNames: ['input-field'],
        placeholder: '',
        maxlength: null,
        required: false,
        classNameBindings: ['isValid::invalid'],
        obsValue: _ember['default'].observer('value', function () {
            if (this.skip) {
                return;
            }

            if (this.$input) {
                this.$input.val(this.get('value'));
            }
        }),
        didInsertElement: function didInsertElement() {
            var _this = this;

            var $label = _ember['default'].$('<label class="lblinp">' + this.get('placeholder') + '</label>');
            this.$input = _ember['default'].$('<input type="' + this.get('type') + '" />');
            this.$input.prop('required', this.get('required'));
            this.$input.attr('placeholder', this.get('placeholder'));
            if (this.get('maxlength')) {
                this.$input.attr('maxlength', this.get('maxlength'));
            }
            var val = this.get('value');
            if (val && val !== '') {
                this.$input.val(this.get('value'));
            }

            if (isInputEventSupported()) {
                this.$input.on('input', function (e) {
                    _ember['default'].run(function () {
                        _this.skip = true;
                        _this.set('value', e.target.value);
                        _this.skip = false;

                        if (_this.change) {
                            _this.change(e);
                        }
                    });
                });
            } else {
                this.$input.keydown(function (e) {
                    _ember['default'].run(function () {
                        if (_this.change) {
                            _this.change(e);
                        }
                    });
                });
                this.$input.keyup(function (e) {
                    _ember['default'].run(function () {
                        _this.skip = true;
                        _this.set('value', e.target.value);
                        _this.skip = false;
                    });
                });
            }
            this.$input.change(function (e) {
                _ember['default'].run(function () {
                    _this.skip = true;
                    _this.set('value', e.target.value);
                    _this.skip = false;

                    if (_this.change) {
                        _this.change(e);
                    }
                });
            });
            this.$().append($label);
            this.$().append(this.$input);
        }
    });

    function isInputEventSupported() {
        var el = document.createElement('input');
        var isSupported = ('oninput' in el);
        if (!isSupported) {
            el.setAttribute('oninput', 'return;');
            isSupported = typeof el['input'] === 'function';
        }
        el = null;
        return isSupported;
    }
});