define('lfrz-login/pods/protected/index/controller', ['exports', 'ember', 'lfrz-login/helpers/ApiHelper'], function (exports, _ember, _lfrzLoginHelpersApiHelper) {
    exports['default'] = _ember['default'].Controller.extend({
        loadingTile: null,
        init: function init() {
            this._super.apply(this, arguments);

            this.onAccept = this.onAccept.bind(this);
        },
        onAccept: function onAccept() {
            var _this = this;

            _lfrzLoginHelpersApiHelper['default'].Post('AcceptDisclaimer').then(function () {
                _this.set('acceptedDisclaimer', true);

                var projectId = _this.get('redirectToProject');
                if (projectId) {
                    var model = _this.get('model');
                    for (var i = 0; i < model.length; i++) {
                        if (model[i].id == projectId) {
                            _this.send('onSelect', model[i]);
                            break;
                        }
                    }
                }
            }, function () {
                alert('Error while accepting disclaimer');
            });
        },
        actions: {
            onSelect: function onSelect(tileModel) {
                this.set('loadingTile', tileModel.id);
                _lfrzLoginHelpersApiHelper['default'].Post('Projects', tileModel.id).then(function (data) {
                    //success
                    window.location = data;
                }, function () {
                    //fail
                });
            }
        }
    });
});