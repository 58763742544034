define('lfrz-login/pods/protected/admin/registrierungen/details/controller', ['exports', 'ember', 'lfrz-login/helpers/ApiHelper'], function (exports, _ember, _lfrzLoginHelpersApiHelper) {
    exports['default'] = _ember['default'].Controller.extend({
        actions: {
            acceptUser: function acceptUser() {
                var _this = this;

                modifyUser.apply(this, [1]).then(function () {
                    _this.set('model.accepted', true);
                });
            },
            rejectUser: function rejectUser() {
                var _this2 = this;

                modifyUser.apply(this, [2]).then(function () {
                    _this2.set('model.rejected', true);
                });
            }
        }
    });

    function modifyUser(status) {
        var _this3 = this;

        var id = this.get('model.id');
        var comment = this.get('model.comment');

        return _lfrzLoginHelpersApiHelper['default'].Put('AdminPendingRegistrations', {
            id: id,
            status: status,
            comment: comment
        }).then(function (data) {
            _this3.send('refreshModelRoute');
        }).fail(function (data) {
            if (data.status == 409) {
                _this3.set('model.conflict', true);
            } else if (data.status == 404) {
                _this3.set('model.notfound', true);
            }
        });
    }
});