define('lfrz-login/pods/login/wrap/index/route', ['exports', 'ember', 'lfrz-login/helpers/ApiHelper'], function (exports, _ember, _lfrzLoginHelpersApiHelper) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model(param) {
            var _this = this;

            return _lfrzLoginHelpersApiHelper['default'].Get('AgrargisWrap/' + param.id).then(function (data) {
                //success
                _ember['default'].run(function () {
                    if (data) {
                        var wrapdata = {
                            id: param.id,
                            betriebid: data.betriebId,
                            name: data.name,
                            email: data.eMail,
                            firstname: data.firstName,
                            lastname: data.lastName
                        };

                        _this.transitionTo('login.register.selbstregistrierung', { queryParams: { wrapdata: wrapdata } });
                        return;
                    }

                    _lfrzLoginHelpersApiHelper['default'].Login(param.id).then(function () {
                        _this.controllerFor(_this.get('routeName')).set('showLoginMessage', true);
                    }).fail(function () {
                        _ember['default'].run(function () {
                            _this.transitionTo('login.wrap.error');
                        });
                    });
                });
                return;
            }, function (data) {
                //fail
                /*jshint eqeqeq: false*/
                if (data.status == 403) {
                    _ember['default'].run(function () {
                        _this.transitionTo('login.disabled');
                    });
                }
                return;
            });
        }
    });
});