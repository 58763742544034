define('lfrz-login/pods/login/reset/index/controller', ['exports', 'ember', 'lfrz-login/helpers/ApiHelper'], function (exports, _ember, _lfrzLoginHelpersApiHelper) {
    exports['default'] = _ember['default'].Controller.extend({
        isSubmitting: false,
        actions: {
            submit: function submit() {
                var _this = this;

                var model = this.get('model');

                model.set('errorMessage', '');

                if (!model.usernameValid) {
                    model.set('errorMessage', 'Keine gültig Betriebsnummer');
                    return;
                }

                var username = model.username;

                model.set('isReset', false);
                this.set('isSubmitting', true);

                _lfrzLoginHelpersApiHelper['default'].Post('PasswordReset', username).then(function () {
                    //success
                    _ember['default'].run(function () {
                        model.set('success', true);
                        _this.set('isSubmitting', false);
                    });
                }, function (data) {
                    //error
                    _ember['default'].run(function () {
                        model.set('errorMessage', _lfrzLoginHelpersApiHelper['default'].GetMessage(data));
                        _this.set('isSubmitting', false);
                    });
                });
            }
        }

    });
});