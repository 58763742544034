define('lfrz-login/pods/login/reset/confirm/index/route', ['exports', 'ember', 'lfrz-login/helpers/ApiHelper'], function (exports, _ember, _lfrzLoginHelpersApiHelper) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model(param) {
            return _lfrzLoginHelpersApiHelper['default'].Get('PasswordReset/' + param.id).then(function () {
                //success
                return _ember['default'].Object.create({ id: param.id });
            }, function () {
                //fail
                return;
            });
        }
    });
});