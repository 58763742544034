define('lfrz-login/pods/components/input-email/component', ['exports', 'ember', 'lfrz-login/pods/components/input-generic/component'], function (exports, _ember, _lfrzLoginPodsComponentsInputGenericComponent) {

    var emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    exports['default'] = _lfrzLoginPodsComponentsInputGenericComponent['default'].extend({
        required: true,
        type: 'email',
        placeholder: 'E-Mail',
        updateIsValid: _ember['default'].observer('value', function () {
            this.set('isValid', emailRegex.test(this.get('value')));
        })
    });
});