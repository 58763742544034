define('lfrz-login/pods/login/index/controller', ['exports', 'ember', 'lfrz-login/helpers/ApiHelper'], function (exports, _ember, _lfrzLoginHelpersApiHelper) {
    exports['default'] = _ember['default'].Controller.extend({
        isSubmitting: false,
        actions: {
            submit: function submit() {
                var _this = this;

                // the current value of the text field
                var model = this.get('model');

                model.set('errorMessage', '');

                if (!model.usernameValid) {
                    model.set('errorMessage', 'Keine gültig Betriebsnummer');
                    return;
                }

                this.set('isSubmitting', true);

                _lfrzLoginHelpersApiHelper['default'].Login(model.username, model.password).then(function () {
                    _ember['default'].run(function () {
                        _this.transitionToRoute('protected.index');
                    });
                }).fail(function (message) {
                    _ember['default'].run.later(function () {
                        _this.set('isSubmitting', false);
                        model.set('password', null);
                        model.set('errorMessage', message);
                    }, 500);
                });
            }
        }
    });
});