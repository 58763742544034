define('lfrz-login/pods/protected/admin/userswitch/controller', ['exports', 'ember', 'lfrz-login/helpers/ApiHelper'], function (exports, _ember, _lfrzLoginHelpersApiHelper) {
    exports['default'] = _ember['default'].Controller.extend({
        actions: {
            switchUser: function switchUser(id) {
                var _this = this;

                _lfrzLoginHelpersApiHelper['default'].Post('MasterUserSwitch', id).then(function (data) {
                    _ember['default'].run(function () {
                        _ember['default'].$.localStorage.remove('authToken');
                        _this.transitionToRoute('login.wrap', data.id);
                    });
                });
            }
        }
    });
});