define('lfrz-login/pods/protected/admin/statistic/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        registerTypeChartData: _ember['default'].computed('model.registerTypes', function () {
            var model = this.get('model.registerTypes');
            var chartData = [['Typ', 'Anzahl'], ['AMA-Pin', model.ama], ['AgrarGIS Selbstregistriert', model.agrarGIS], ['Selbstregistriert', model.selbstregistriert]];

            return chartData;
        }),
        loginsDayChartData: _ember['default'].computed('model.uniqueLoginsDay', function () {
            var model = this.get('model.uniqueLoginsDay');
            var chartData = [['Tag', 'Anzahl']];
            for (var i = 0; i < model.length; i++) {
                chartData.push([new Date(model[i].date), model[i].value]);
            }

            return chartData;
        }),
        loginsMonthChartData: _ember['default'].computed('model.uniqueLoginsMonth', function () {
            var model = this.get('model.uniqueLoginsMonth');
            var chartData = [['Monat', 'Anzahl']];
            for (var i = 0; i < model.length; i++) {
                chartData.push([new Date(model[i].date), model[i].value]);
            }

            return chartData;
        }),
        loginsYearChartData: _ember['default'].computed('model.uniqueLoginsYear', function () {
            var model = this.get('model.uniqueLoginsYear');
            var chartData = [['Jahr', 'Anzahl']];
            for (var i = 0; i < model.length; i++) {
                chartData.push([new Date(model[i].date), model[i].value]);
            }

            return chartData;
        }),
        topProjectsChartData: _ember['default'].computed('model.topProjects', function () {
            var model = this.get('model.topProjects');
            var chartData = [['Projekt', 'Anzahl']];

            var projects = this.get('model.projects');

            for (var i = 0; i < model.length; i++) {
                chartData.push([getProjectById(projects, model[i].projectId).name, model[i].value]);
            }

            return chartData;
        }),
        topProjectsDayChartData: _ember['default'].computed('model.topProjectsDay', function () {
            var model = this.get('model.topProjectsDay');
            var projects = this.get('model.projects');

            //var chartData = new google.visualization.DataTable();
            //chartData.addColumn('date', 'Tag');

            var chartColumns = ['Tag'];

            var columns = [];
            // add every project as its own column
            projects.forEach(function (proj) {
                var col = { id: proj.id, type: 'number', label: proj.name };
                columns.push(col);
                chartColumns.push(col);
            });

            // find all unique dates
            var uniqueDates = model.reduce(function (arr, el) {
                var matches = arr.filter(function (el2) {
                    return el.date == el2;
                });
                if (matches.length == 0) {
                    arr.push(el.date);
                }
                return arr;
            }, []);

            var chartData = [chartColumns];

            // create a row for each date
            uniqueDates.forEach(function (date) {
                var row = [];

                row.push(new Date(date));

                // for every column we added we need the values for it
                columns.forEach(function (col) {
                    // try to find that one column that has the specified project and date
                    var matched = model.filter(function (val) {
                        return val.projectId == col.id && val.date == date;
                    });

                    var rowValue = null;

                    if (matched.length == 1) {
                        rowValue = matched[0].value;
                    }

                    row.push(rowValue);
                });

                chartData.push(row);
            });

            return chartData;
        })
    });

    function getProjectById(projects, id) {
        var matches = projects.filter(function (val) {
            return val.id == id;
        });

        if (matches.length == 1) {
            return matches[0];
        }

        return { id: 0, name: 'Unbekanntes Projekt' };
    }
});