define('lfrz-login/pods/components/ladda-button/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'button',
        classNames: ['ladda-button'],
        attributeBindings: ['data-style'],
        'data-style': 'contract',
        ladda: null,
        observeSubmitting: _ember['default'].observer('isSubmitting', function () {
            var l = this.get('ladda');
            if (!l) {
                return;
            }

            if (this.get('isSubmitting')) {
                l.start();
            } else {
                l.stop();
            }
        }),
        didInsertElement: function didInsertElement() {
            var l = Ladda.create(this.$()[0]);
            this.set('ladda', l);
        }
    });
});
/*globals Ladda */