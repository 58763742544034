define('lfrz-login/helpers/ApiHelper', ['exports', 'ember', 'lfrz-login/config/environment', 'lfrz-login/modules/GlobalObjects'], function (exports, _ember, _lfrzLoginConfigEnvironment, _lfrzLoginModulesGlobalObjects) {

    var ApiHelper = {

        GetResponseObject: function GetResponseObject(data) {
            if (!data || !data.responseText) {
                return null;
            }
            return data.responseJSON || JSON.parse(data.responseText);
        },
        GetMessage: function GetMessage(data) {
            return (data.responseJSON || JSON.parse(data.responseText) || { message: '' }).message;
        },

        Request: function Request(type, url, param, noSecondTry) {
            var _this = this;

            var deferred = _ember['default'].$.Deferred();

            var alertError = function alertError(data) {
                deferred.reject(data);
                if (!data || data.status === 500) {
                    //var dataMessage = (data.responseJSON || JSON.parse(data.responseText)) || {};
                    alert('error: ' + data.responseText);
                }
            };
            _ember['default'].$.ajax({
                url: _lfrzLoginConfigEnvironment['default'].apiVer + '/api/' + url,
                type: type,
                data: typeof param !== 'undefined' && param != null ? JSON.stringify(param) : null,
                contentType: 'application/json',
                beforeSend: function beforeSend(request) {
                    if (_ember['default'].$.localStorage.isSet('authToken')) {
                        request.setRequestHeader('Authorization', 'Bearer ' + _ember['default'].$.localStorage.get('authToken').access_token);
                    }
                }
            }).then(function (data) {
                deferred.resolve(data);
            }, function (data) {
                if (data.status === 401 && !noSecondTry) {
                    _this.RefreshToken().then(function () {
                        _this.Request(type, url, param, true).then(function (data) {
                            deferred.resolve(data);
                        }, alertError);
                    }, alertError);
                } else {
                    alertError(data);
                }
            });

            return deferred.promise();
        },

        Get: function Get(url, param) {
            return this.Request('GET', url, param);
        },

        Post: function Post(url, param) {
            return this.Request('POST', url, param);
        },

        Put: function Put(url, param) {
            return this.Request('PUT', url, param);
        },

        RefreshToken: function RefreshToken() {
            if (!_ember['default'].$.localStorage.isSet('authToken')) {
                window.location.hash = '/login';
                var def = _ember['default'].$.Deferred();
                def.resolve();
                return def;
            }
            return _ember['default'].$.ajax({
                url: _lfrzLoginConfigEnvironment['default'].apiVer + '/token',
                type: 'POST',
                data: { refresh_token: _ember['default'].$.localStorage.get('authToken').refresh_token, grant_type: 'refresh_token' },
                contentType: 'application/x-www-form-urlencoded'
            }).then(function (data) {
                _ember['default'].$.localStorage.set('authToken', data);
            }, function () {
                window.location.hash = '/login';
            });
        },

        Login: function Login(username, password) {
            return _ember['default'].$.ajax({
                url: _lfrzLoginConfigEnvironment['default'].apiVer + '/token',
                type: 'POST',
                data: { username: username, password: password, grant_type: 'password' },
                contentType: 'application/x-www-form-urlencoded'
            }).then(function (data) {
                //success
                _ember['default'].$.localStorage.set('authToken', data);
            }, function (data) {
                //fail
                if (data.status === 400) {
                    var dataMessage = data.responseJSON || JSON.parse(data.responseText) || {};
                    if (dataMessage.error_description) {
                        return dataMessage.error_description;
                    }
                }

                return 'Server Fehler. Versuchen Sie es in ein paar Minuten erneut.';
            });
        },

        Logout: function Logout() {
            if (!_ember['default'].$.localStorage.isSet('authToken')) {
                return;
            }

            var token = _ember['default'].$.localStorage.get('authToken').refresh_token;
            return this.Post('Logout', token).done(function () {
                _ember['default'].$.localStorage.remove('authToken');
            });
        }
    };

    exports['default'] = ApiHelper;
});