define('lfrz-login/pods/components/google-chart/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        didInsertElement: function didInsertElement() {

            if (!google) {
                throw 'google-chart: missing google library';
            }
            var modelData = this.get('data');

            if (!modelData) {
                return;
            }

            var data = google.visualization.arrayToDataTable(modelData);
            var options = {};

            var chartType = this.get('type');
            var chart = null;
            switch (chartType) {
                case 'pie':
                    chart = new google.visualization.PieChart(this.$('div')[0]);
                    break;
                case 'line':
                    chart = new google.visualization.LineChart(this.$('div')[0]);
                    options.legend = { position: 'top' };
                    if (this.get('format')) {
                        options.hAxis = { format: this.get('format') };
                    }
                    options.vAxis = { minValue: 0, viewWindow: { min: 0 } };
                    break;
                default:
                    chart = new google.visualization.ColumnChart(this.$('div')[0]);
                    options.legend = { position: 'top', maxLines: 4 };
                    if (this.get('format')) {
                        options.hAxis = { format: this.get('format') };
                    }
                    //options.isStacked = true;
                    options.vAxis = { minValue: 0, viewWindow: { min: 0 } };
                    break;
            }
            chart.draw(data, options);
        }
    });
});