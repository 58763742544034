define('lfrz-login/pods/protected/admin/registrierungen/details/route', ['exports', 'ember', 'lfrz-login/helpers/ApiHelper'], function (exports, _ember, _lfrzLoginHelpersApiHelper) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model(params) {
            if (!_ember['default'].$.localStorage.isSet('authToken')) {
                this.transitionTo('login.index');
                return;
            }

            var model = this.modelFor('protected.admin.registrierungen');

            for (var i = 0; i < model.length; i++) {
                if (model[i].id == params.id) {
                    return model[i];
                }
            }

            var cachedModel = this.modelCache;

            if (cachedModel && cachedModel.id == params.id) {
                return cachedModel;
            }

            return null;
        },
        afterModel: function afterModel(model) {
            if (model == null) {
                this.transitionTo('protected.admin.registrierungen');
                return;
            }

            this.modelCache = model;
        }
    });
});