define('lfrz-login/pods/login/register/index/controller', ['exports', 'ember', 'lfrz-login/helpers/ApiHelper'], function (exports, _ember, _lfrzLoginHelpersApiHelper) {
    exports['default'] = _ember['default'].Controller.extend({
        isCheckingBetriebsnummer: false,
        actions: {
            checkBetriebsnummer: function checkBetriebsnummer() {
                var _this = this;

                // the current value of the text field
                var model = this.get('model');

                model.set('errorMessage', '');

                if (!model.betriebsnummerValid) {
                    model.set('errorMessage', 'Keine gültig Betriebsnummer');
                    return;
                }

                if (model.betriebsnummer >= 700000000) {
                    model.set('errorMessage', 'Selbstregistrierte AgrarGIS Betriebsnummern können nicht mehr verwendet werden');
                    return;
                }

                this.set('isCheckingBetriebsnummer', true);

                _lfrzLoginHelpersApiHelper['default'].Get('AgrargisRegister/CheckBetriebsnummer/' + model.betriebsnummer).then(function () {
                    _ember['default'].run.later(_this, function () {
                        _this.transitionToRoute('login.register.datenübernahme', { queryParams: { betriebsnummer: model.betriebsnummer } });
                    }, 500);
                }).fail(function (message) {
                    _ember['default'].run.later(_this, function () {
                        _this.set('isCheckingBetriebsnummer', false);

                        switch (message.status) {
                            case 404:
                                model.set('errorMessage', 'Betriebsnummer nicht gefunden');
                                break;
                            case 409:
                                var obj = _lfrzLoginHelpersApiHelper['default'].GetResponseObject(message);

                                if (obj && obj.pending) {
                                    model.set('errorMessage', 'Registrierungsanfrage bereits in Bearbeitung');
                                } else {
                                    model.set('errorMessage', 'Betriebsnummer bereits registriert');
                                }
                                break;
                            default:
                                model.set('errorMessage', 'Ein unbekannter Fehler ist aufgetreten');
                                break;
                        }
                    }, 500);
                });
            }
        }
    });
});