define('lfrz-login/pods/components/input-betriebsnummer/component', ['exports', 'ember', 'lfrz-login/pods/components/input-generic/component'], function (exports, _ember, _lfrzLoginPodsComponentsInputGenericComponent) {
    exports['default'] = _lfrzLoginPodsComponentsInputGenericComponent['default'].extend({
        required: true,
        type: 'text',
        placeholder: 'Betriebsnummer',
        maxlength: 9,
        updateIsValid: _ember['default'].observer('value', function () {
            this.set('isValid', _ember['default'].$.isNumeric(this.get('value')));
        }),
        keyDown: function keyDown(e) {
            allowNumberInput(e);
        }
    });

    function allowNumberInput(event) {
        // Allow: delete, backspace, tab, escape, enter
        if (event.keyCode === 46 || event.keyCode === 8 || event.keyCode === 9 || event.keyCode === 27 || event.keyCode === 13 ||
        // Allow: Ctrl+A
        event.keyCode === 65 && event.ctrlKey === true ||
        // Allow: Ctrl+C
        event.keyCode === 67 && event.ctrlKey === true ||
        // Allow: Ctrl+V
        event.keyCode === 86 && event.ctrlKey === true ||
        // Allow: Ctrl+X
        event.keyCode === 88 && event.ctrlKey === true ||
        // Allow: home, end, left, right
        event.keyCode >= 35 && event.keyCode <= 39 ||
        // F1 - F12
        event.keyCode >= 112 && event.keyCode <= 129) {
            // let it happen, don't do anything
            return;
        }

        // Ensure that it is a number and stop the keypress
        if (event.shiftKey || (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) {
            event.preventDefault();
        }
    }
});