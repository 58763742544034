define('lfrz-login/pods/login/register/datenübernahme/controller', ['exports', 'ember', 'lfrz-login/helpers/ApiHelper'], function (exports, _ember, _lfrzLoginHelpersApiHelper) {
    exports['default'] = _ember['default'].Controller.extend({
        isSubmitting: false,
        actions: {
            submit: function submit() {
                var _this = this;

                var model = this.get('model');

                if (this.get('isSubmitting')) {
                    return;
                }

                model.set('errorMessage', '');
                this.set('isSubmitting', true);

                var registerData = {
                    betriebsnummer: model.betriebsnummer,
                    firstname: model.firstname,
                    lastname: model.lastname,
                    email: model.email,
                    address: model.address,
                    postcode: model.postcode,
                    city: model.city,
                    phonenumber: model.phonenumber
                };

                _lfrzLoginHelpersApiHelper['default'].Post('AgrargisRegister', registerData).then(function () {
                    _ember['default'].run(function () {
                        model.set('success', true);
                        _this.set('isSubmitting', false);
                    });
                }, function (data) {
                    _ember['default'].run(function () {
                        model.set('errorMessage', _lfrzLoginHelpersApiHelper['default'].GetMessage(data));
                        _this.set('isSubmitting', false);
                    });
                });
            }
        }
    });
});