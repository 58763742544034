define('lfrz-login/router', ['exports', 'ember', 'lfrz-login/config/environment'], function (exports, _ember, _lfrzLoginConfigEnvironment) {

    var Router = _ember['default'].Router.extend({
        location: _lfrzLoginConfigEnvironment['default'].locationType
    });

    Router.map(function () {
        this.route('login', function () {
            this.route('reset', function () {
                this.route('confirm', function () {
                    this.route('index', { path: ':id' });
                    this.route('success');
                });
            });

            this.route('register', function () {
                this.route('confirm', function () {
                    this.route('index', { path: ':id' });
                    this.route('success');
                });
                this.route('selbstregistrierung');
                this.route('datenübernahme');
            });

            this.route('wrap', function () {
                this.route('index', { path: ':id' });
            });

            this.route('logout');
            this.route('disabled');
        });

        this.route('contact');
        this.route('nutzungsbedingungen');

        this.route('protected', { path: '' }, function () {
            this.route('projects');
            this.route('changepassword');
            this.route('admin', function () {
                this.route('registrierungen', function () {
                    this.route('details', { path: ':id' });
                });
                this.route('userswitch');
                this.route('statistic');
            });
        });
    });
    exports['default'] = Router;
});