define("lfrz-login/pods/login/reset/confirm/index/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.12",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 8
            },
            "end": {
              "line": 16,
              "column": 75
            }
          },
          "moduleName": "lfrz-login/pods/login/reset/confirm/index/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Neues Passwort festlegen");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.12",
          "loc": {
            "source": null,
            "start": {
              "line": 21,
              "column": 8
            },
            "end": {
              "line": 21,
              "column": 50
            }
          },
          "moduleName": "lfrz-login/pods/login/reset/confirm/index/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("zurück zum Login");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.12",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 23,
            "column": 6
          }
        },
        "moduleName": "lfrz-login/pods/login/reset/confirm/index/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "reset");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h2");
        var el3 = dom.createTextNode("Neues Passwort");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("\n        Das Passwort muss mindestens 6 unterschiedliche Zeichen enthalten.\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("form");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "sub-links");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2]);
        var element1 = dom.childAt(element0, [7]);
        var element2 = dom.childAt(element1, [1]);
        var morphs = new Array(8);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(element0, 5, 5);
        morphs[2] = dom.createElementMorph(element1);
        morphs[3] = dom.createMorphAt(element2, 1, 1);
        morphs[4] = dom.createMorphAt(element2, 3, 3);
        morphs[5] = dom.createMorphAt(dom.childAt(element1, [3]), 0, 0);
        morphs[6] = dom.createMorphAt(element1, 5, 5);
        morphs[7] = dom.createMorphAt(dom.childAt(element0, [9]), 1, 1);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "title", ["Neues Passwort"], [], ["loc", [null, [1, 0], [1, 26]]]], ["inline", "box-error", [], ["message", ["subexpr", "@mut", [["get", "model.errorMessage", ["loc", [null, [9, 24], [9, 42]]]]], [], []]], ["loc", [null, [9, 4], [9, 44]]]], ["element", "action", ["submit"], ["on", "submit"], ["loc", [null, [10, 10], [10, 41]]]], ["inline", "input-password", [], ["maxlength", "128", "value", ["subexpr", "@mut", [["get", "model.password", ["loc", [null, [12, 51], [12, 65]]]]], [], []], "placeholder", "Neues Passwort", "class", "has-password-strength"], ["loc", [null, [12, 12], [12, 126]]]], ["inline", "password-strength", [], ["password", ["subexpr", "@mut", [["get", "model.password", ["loc", [null, [13, 41], [13, 55]]]]], [], []], "isMinimum", ["subexpr", "@mut", [["get", "model.passwordIsMinimum", ["loc", [null, [13, 66], [13, 89]]]]], [], []]], ["loc", [null, [13, 12], [13, 91]]]], ["inline", "input-password", [], ["maxlength", "128", "value", ["subexpr", "@mut", [["get", "model.passwordConfirm", ["loc", [null, [15, 52], [15, 73]]]]], [], []], "placeholder", "Neues Passwort bestätigen"], ["loc", [null, [15, 13], [15, 115]]]], ["block", "ladda-button", [], ["isSubmitting", ["subexpr", "@mut", [["get", "isSubmitting", ["loc", [null, [16, 37], [16, 49]]]]], [], []]], 0, null, ["loc", [null, [16, 8], [16, 92]]]], ["block", "link-to", ["login.index"], [], 1, null, ["loc", [null, [21, 8], [21, 62]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});