define('lfrz-login/pods/protected/admin/registrierungen/index/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        isRefreshing: false,
        actions: {
            refreshModel: function refreshModel() {
                this.set('isRefreshing', true);
                this.send('refreshModelRoute');
            }
        }
    });
});