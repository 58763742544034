define("lfrz-login/pods/nutzungsbedingungen/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.12",
          "loc": {
            "source": null,
            "start": {
              "line": 86,
              "column": 4
            },
            "end": {
              "line": 93,
              "column": 4
            }
          },
          "moduleName": "lfrz-login/pods/nutzungsbedingungen/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" Ja, ich habe die Nutzungsbedingungen, Copyrightverweise und den Haftungsausschluss gelesen und bin einverstanden.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          var el3 = dom.createTextNode("Weiter");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [3, 1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 0, 0);
          morphs[1] = dom.createAttrMorph(element0, 'disabled');
          morphs[2] = dom.createAttrMorph(element0, 'onclick');
          return morphs;
        },
        statements: [["inline", "input", [], ["type", "checkbox", "checked", ["subexpr", "@mut", [["get", "accepted", ["loc", [null, [88, 47], [88, 55]]]]], [], []]], ["loc", [null, [88, 15], [88, 57]]]], ["attribute", "disabled", ["subexpr", "if", [["get", "accepted", ["loc", [null, [91, 30], [91, 38]]]], false, true], [], ["loc", [null, [91, 25], [91, 51]]]]], ["attribute", "onclick", ["subexpr", "action", [["get", "onAccept", ["loc", [null, [91, 69], [91, 77]]]]], [], ["loc", [null, [91, 60], [91, 79]]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.12",
            "loc": {
              "source": null,
              "start": {
                "line": 95,
                "column": 8
              },
              "end": {
                "line": 95,
                "column": 44
              }
            },
            "moduleName": "lfrz-login/pods/nutzungsbedingungen/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("zurück");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.12",
          "loc": {
            "source": null,
            "start": {
              "line": 93,
              "column": 4
            },
            "end": {
              "line": 97,
              "column": 4
            }
          },
          "moduleName": "lfrz-login/pods/nutzungsbedingungen/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "sub-links");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "link-to", ["protected.index"], [], 0, null, ["loc", [null, [95, 8], [95, 56]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.12",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 99,
            "column": 6
          }
        },
        "moduleName": "lfrz-login/pods/nutzungsbedingungen/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("section");
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h2");
        var el4 = dom.createTextNode("Nutzungsbedingungen, Copyrightverweise und Haftungsausschluss");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h3");
        var el4 = dom.createTextNode("Bitte bestätigen Sie am Ende dieses Dokumentes, dass Sie sich mit den Nutzungsbedingungen, Copyrightverweisen und dem Haftungsausschluss einverstanden erklären");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "style", "font-size: 0.75em; text-align: left");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h4");
        var el6 = dom.createTextNode("Tätigkeitsbereich:");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Die Internetauftritte der Landwirtschaftskammern und ihres Vertragspartners ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5, "class", "no-break");
        var el6 = dom.createTextNode("UNIDATA GEODESIGN GMBH");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" mit der Portaladresse ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "href", "http://www.lko.at");
        dom.setAttribute(el5, "target", "_blank");
        var el6 = dom.createTextNode("www.lko.at");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" bzw. ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "href", "http://forst.unidata.at");
        dom.setAttribute(el5, "target", "_blank");
        var el6 = dom.createTextNode("forst.unidata.at");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" sind ein Gemeinschaftsprojekt der Landwirtschaftskammern in Österreich Landwirtschaftskammern und ihres Vertragspartners ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5, "class", "no-break");
        var el6 = dom.createTextNode("UNIDATA GEODESIGN GMBH");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(". Jeder Partner ist inhaltlich für seinen Bereich des Portals verantwortlich. Die Landwirtschaftskammer Österreich stellt in Kooperation mit dem Vertragspartner ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5, "class", "no-break");
        var el6 = dom.createTextNode("UNIDATA GEODESIGN GMBH");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" die technische Infrastruktur zur Verfügung.\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Förderung der Nutzung des Internets durch die im ländlichen Raum lebenden und wirtschaftenden Menschen, insbesondere durch die bäuerliche Bevölkerung, mittels eines vielseitigen Online-Serviceangebots.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h4");
        var el6 = dom.createTextNode("Richtung der Website (\"Blattlinie\"):");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Förderung der Ziele des Tätigkeitsbereiches.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h4");
        var el6 = dom.createTextNode("Copyright:");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Der Inhalt dieser Plattform ist urheberrechtlich geschützt. Die Informationen sind nur für die persönliche Verwendung bestimmt. Jede weitergehende Nutzung, insbesondere die Speicherung in Datenbanken, Vervielfältigung und jede Form von gewerblicher Nutzung sowie die Weitergabe an Dritte - auch in Teilen oder in überarbeiteter Form -, ohne Zustimmung der jeweiligen Organisation ist untersagt.\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Hyperlinks auf ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "href", "http://www.lko.at");
        dom.setAttribute(el5, "target", "_blank");
        var el6 = dom.createTextNode("www.lko.at");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(", ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "href", "http://forst.unidata.at");
        dom.setAttribute(el5, "target", "_blank");
        var el6 = dom.createTextNode("forst.unidata.at");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" und die Seiten der Landeskammern sowie der mit ihnen verbundenen Vertragspartner und Organisationen sind willkommen. Bei Verlinkung auf unsere Seiten müssen diese unbedingt in einem neuen Browser-Fenster geöffnet und dürfen keinesfalls als Teil oder in der Optik der verlinkenden Anwendung dargestellt werden. Jede Einbindung einzelner Seiten in fremde Frames ist ohne ausdrückliche schriftliche Zustimmung zu unterlassen.\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Ebenso bedarf eine Übernahme von Texten der schriftlichen Zustimmung des Betreibers der Seite, eine Übernahme von Bildern ist nicht gestattet. Davon ausgenommen sind Bilder, die ausdrücklich zum Download und zur Nutzung angeboten werden.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h4");
        var el6 = dom.createTextNode("Haftungsausschluss:");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Die Inhalte des Internetportals der Landwirtschaftskammern und ihres Vertragspartners ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5, "class", "no-break");
        var el6 = dom.createTextNode("UNIDATA GEODESIGN GMBH");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" mit der Portaladresse ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "href", "http://www.lko.at");
        dom.setAttribute(el5, "target", "_blank");
        var el6 = dom.createTextNode("www.lko.at");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" bzw. ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "href", "http://forst.unidata.at");
        dom.setAttribute(el5, "target", "_blank");
        var el6 = dom.createTextNode("forst.unidata.at");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" werden von deren Mitarbeitern nach bestem Wissen und Gewissen recherchiert und gestaltet. Die Betreiber bemühen sich um Aktualität, Vollständigkeit und Richtigkeit der Inhalte, schließen aber trotzdem sämtliche Haftungen und Gewährleistungen für allenfalls unvollständige oder fehlerhafte Inhalte aus. Die Inhalte sind als allgemeine Information und nicht als Beratung zu verstehen.\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Es wird auch keine Gewähr für die Verfügbarkeit oder den Betrieb dieses Portals und seiner Inhalte übernommen.\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Jede Haftung für unmittelbare, mittelbare oder sonstige Schäden, unabhängig von deren Ursachen, die aus der Benutzung oder Nichtverfügbarkeit der Daten und Informationen des Internetauftritts der Landwirtschaftskammern und ihres Vertragspartners ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5, "class", "no-break");
        var el6 = dom.createTextNode("UNIDATA GEODESIGN GMBH");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" mit der Portaladresse ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "href", "http://www.lko.at");
        dom.setAttribute(el5, "target", "_blank");
        var el6 = dom.createTextNode("www.lko.at");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" bzw. ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "href", "http://forst.unidata.at");
        dom.setAttribute(el5, "target", "_blank");
        var el6 = dom.createTextNode("forst.unidata.at");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" erwachsen, wird, soweit rechtlich zulässig, ausgeschlossen.\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Bei Links auf externe Webseiten Dritter haben die Betreiber des Internetportals der Landwirtschaftskammern und ihres Vertragspartners ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5, "class", "no-break");
        var el6 = dom.createTextNode("UNIDATA GEODESIGN GMBH");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" mit der Portaladresse ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "href", "http://www.lko.at");
        dom.setAttribute(el5, "target", "_blank");
        var el6 = dom.createTextNode("www.lko.at");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" bzw. ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "href", "http://forst.unidata.at");
        dom.setAttribute(el5, "target", "_blank");
        var el6 = dom.createTextNode("forst.unidata.at");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" keinen Einfluss auf die Inhalte dieser verlinkten Webseiten. Für die Richtigkeit der Inhalte ist immer der jeweilige Anbieter oder Betreiber verantwortlich. Auch hierfür übernehmen die Landwirtschaftskammern und ihr Vertragspartner ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5, "class", "no-break");
        var el6 = dom.createTextNode("UNIDATA GEODESIGN GMBH");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" mit der Portaladresse ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "href", "http://www.lko.at");
        dom.setAttribute(el5, "target", "_blank");
        var el6 = dom.createTextNode("www.lko.at");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" bzw. ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "href", "http://forst.unidata.at");
        dom.setAttribute(el5, "target", "_blank");
        var el6 = dom.createTextNode("forst.unidata.at");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" keinerlei Haftung und/oder Gewährleistung. Fremde Webseiten wurden wir zum Zeitpunkt der Verlinkung - soweit möglich und zumutbar - auf Qualität und mögliche Rechtsverstöße überprüft. Eine ständige Überprüfung sämtlicher Inhalte der verlinkten Seiten ist aber unmöglich.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h4");
        var el6 = dom.createTextNode("Newsletter, Anfragebeantwortungen und Inhalte aus interaktiven Applikationen:");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Für Newsletter, die von der Internetplattform der Landwirtschaftskammern und ihres Vertragspartners ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5, "class", "no-break");
        var el6 = dom.createTextNode("UNIDATA GEODESIGN GMBH");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" mit der Portaladresse ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "href", "http://www.lko.at");
        dom.setAttribute(el5, "target", "_blank");
        var el6 = dom.createTextNode("www.lko.at");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" bzw. ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "href", "http://forst.unidata.at");
        dom.setAttribute(el5, "target", "_blank");
        var el6 = dom.createTextNode("forst.unidata.at");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" versendet werden, für Anfragebeantwortungen per E-Mail oder Telefon sowie für andere Inhalte aus interaktiven Applikationen gelten die oben angeführten Bestimmungen aus Copyright und Haftungsausschluss sinngemäß.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h4");
        var el6 = dom.createTextNode("Sprachliche Gleichbehandlung:");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Soweit personenbezogene Bezeichnungen nur in männlicher Form erfolgen, beziehen sie sich auf Frauen und Männer in gleicher Weise. Dies dient der optimalen Lesbarkeit des Contents.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h4");
        var el6 = dom.createTextNode("Zugänglichkeit:");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Die Landwirtschaftskammern und ihr Vertragspartner ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5, "class", "no-break");
        var el6 = dom.createTextNode("UNIDATA GEODESIGN GMBH");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" mit der Portaladresse ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "href", "http://www.lko.at");
        dom.setAttribute(el5, "target", "_blank");
        var el6 = dom.createTextNode("www.lko.at");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" bzw. ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "href", "http://forst.unidata.at");
        dom.setAttribute(el5, "target", "_blank");
        var el6 = dom.createTextNode("forst.unidata.at");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" bemühen sich, einen Zugang zu Informationen für alle sicherzustellen. Einen barrierefreien Zugang zu garantieren, ist mit den derzeitigen technischen Mitteln jedoch nicht vollständig möglich.\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Zur Verbesserung unserer Services sind wir auf Ihre Rückmeldung angewiesen. Bitte, teilen Sie uns mit, wenn Sie Schwierigkeiten haben!\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Senden Sie Ihre Rückmeldung an ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "href", "mailto:office@lk-oe.at");
        var el6 = dom.createTextNode("office@lk-oe.at");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" bzw. ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "href", "mailto:office@office@unidata.at");
        var el6 = dom.createTextNode("office@unidata.at");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(".\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h4");
        var el6 = dom.createTextNode("8 Datenschutzerklärung:");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Diese Erklärung bezieht sich auf die Daten, die durch den Besuch der Webseite der Landwirtschaftskammern bekannt werden und informiert darüber, wie mit den Daten umgegangen wird. Eine Weitergabe von Daten an Dritte durch die Landwirtschaftskammern und ihres Vertragspartners ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5, "class", "no-break");
        var el6 = dom.createTextNode("UNIDATA GEODESIGN GMBH");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" mit der Portaladresse ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "href", "http://www.lko.at");
        dom.setAttribute(el5, "target", "_blank");
        var el6 = dom.createTextNode("www.lko.at");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" bzw. ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "href", "http://forst.unidata.at");
        dom.setAttribute(el5, "target", "_blank");
        var el6 = dom.createTextNode("forst.unidata.at");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" erfolgt nicht. Die Daten sind bestmöglich technisch sowie durch Passwörter geschützt, sodass fremde Personen keinen Zugriff darauf haben.\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Persönliche Daten und ihre Anwendung?\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Um einige Services (z. B.: Newsletter) nutzen zu können, müssen Sie persönliche Daten wie den Namen, die Anschrift oder die E-Mail-Adresse angeben. Die Daten werden in unserer Datenbank gespeichert.\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Zudem werden automatisch und ausschließlich für interne statistische Zwecke Daten in Logfiles gespeichert, die als sogenannte Cookies von Ihrem Browser während des Besuches übermittelt werden.\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Dabei werden erfasst:\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("ul");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("IP-Adresse des anfragenden Rechners");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("Datum und Uhrzeit des Zugriffs des anfragenden Rechners");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("Dauer des Zugriffs Name und URL der abgerufenen Datei");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("Erkennungsdaten des verwendeten Browser- und Betriebssystems");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("Webseite, von der aus der Zugriff erfolgt ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Die statistische Auswertung dieser Daten erfolgt zum Zweck der technischen Administration der Website sowie zur Optimierung des inhaltlichen Angebotes. Die IP-Adresse wird nur in Ausnahmefällen - z. B. zum Nachforschen von Hackerangriffen - ausgewertet.\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Bearbeitung und Löschen der Daten/Abbestellen von Newslettern\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Sie haben jederzeit das Recht, Ihre eingetragenen persönlichen Daten wie E-Mail-Adressen zu korrigieren.\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [2]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createAttrMorph(element1, 'id');
        morphs[2] = dom.createAttrMorph(element1, 'class');
        morphs[3] = dom.createMorphAt(dom.childAt(element1, [1]), 7, 7);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "title", ["Nutzungsbedingungen"], [], ["loc", [null, [1, 0], [1, 31]]]], ["attribute", "id", ["subexpr", "unless", [["get", "onAccept", ["loc", [null, [3, 17], [3, 25]]]], "contact-wrap"], [], ["loc", [null, [3, 8], [3, 42]]]]], ["attribute", "class", ["subexpr", "unless", [["get", "onAccept", ["loc", [null, [3, 58], [3, 66]]]], "page-wrap"], [], ["loc", [null, [3, 49], [3, 80]]]]], ["block", "if", [["get", "onAccept", ["loc", [null, [86, 10], [86, 18]]]]], [], 0, 1, ["loc", [null, [86, 4], [97, 11]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});