define('lfrz-login/pods/protected/admin/registrierungen/route', ['exports', 'ember', 'lfrz-login/helpers/ApiHelper', 'lfrz-login/modules/GlobalObjects'], function (exports, _ember, _lfrzLoginHelpersApiHelper, _lfrzLoginModulesGlobalObjects) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model() {
            if (!_ember['default'].$.localStorage.isSet('authToken')) {
                this.transitionTo('login.index');
                return;
            }

            return _lfrzLoginHelpersApiHelper['default'].Get('AdminPendingRegistrations').then(function (data) {
                //success
                _lfrzLoginModulesGlobalObjects['default'].set('pendingRegistrations', data.length || null);
                return data;
            }, function () {
                //fail
                return;
            });
        },
        actions: {
            refreshModelRoute: function refreshModelRoute() {
                this.refresh();
            }
        }
    });
});