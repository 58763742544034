define('lfrz-login/pods/components/password-strength/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        password: null,
        isMinimum: false,
        classNames: ['password-strength-wrap'],
        score: _ember['default'].computed('password', function () {
            return scorePassword(this.get('password'));
        }),
        scoreClass: _ember['default'].computed('score', function () {
            var score = this.get('score');
            var password = this.get('password');

            if ((password || '').length === 0) {
                this.set('isMinimum', false);
                return 'score-none';
            } else if (score > 80) {
                this.set('isMinimum', true);
                return 'score-good';
            } else if (score > 60) {
                this.set('isMinimum', true);
                return 'score-ok';
            } else if (score >= 30) {
                this.set('isMinimum', true);
                return 'score-weak';
            }

            this.set('isMinimum', false);
            return 'score-invalid';
        })
    });
    //scoreWidthClass: Ember.computed('score', function() {
    //    return 'score-' + this.get('score');
    //})

    function scorePassword(pass) {
        var score = 0;
        if (!pass || pass.length < 6) {
            return score;
        }
        // award every unique letter until 5 repetitions
        var letters = {};
        for (var i = 0; i < pass.length; i++) {
            letters[pass[i]] = (letters[pass[i]] || 0) + 1;
            score += 5.0 / letters[pass[i]];
        }

        // bonus points for mixing it up
        var variations = {
            digits: /\d/.test(pass),
            lower: /[a-z]/.test(pass),
            upper: /[A-Z]/.test(pass),
            nonWords: /\W/.test(pass)
        };

        var variationCount = 0;
        for (var check in variations) {
            variationCount += variations[check] === true ? 1 : 0;
        }
        score += (variationCount - 1) * 10;

        return parseInt(score);
    }
});