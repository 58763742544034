define('lfrz-login/pods/protected/admin/route', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model() {
            var token = _ember['default'].$.localStorage.get('authToken');
            if (!token) {
                return null;
            }

            var obj = {
                isAdmin: token.isAdmin && token.isAdmin >= 1,
                isMaster: token.isMaster && token.isMaster >= 1
            };

            return _ember['default'].Object.create(obj);
        },
        afterModel: function afterModel(model) {
            if (!model || !model.isAdmin) {
                this.transitionTo('protected');
                return;
            }
        }
    });
});