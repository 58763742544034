define('lfrz-login/pods/login/reset/confirm/index/controller', ['exports', 'ember', 'lfrz-login/helpers/ApiHelper'], function (exports, _ember, _lfrzLoginHelpersApiHelper) {
    exports['default'] = _ember['default'].Controller.extend({
        actions: {
            submit: function submit() {
                var _this = this;

                var model = this.get('model');

                if (!model.passwordIsMinimum) {
                    model.set('errorMessage', 'Passwort zu schwach');
                    return;
                } else if (model.password !== model.passwordConfirm) {
                    model.set('errorMessage', 'Passwörter stimmen nicht überein');
                    return;
                }

                model.set('errorMessage', '');

                _lfrzLoginHelpersApiHelper['default'].Put('PasswordReset/' + model.id, model.password).then(function () {
                    _ember['default'].run(function () {
                        _this.transitionToRoute('login.reset.confirm.success');
                    });
                }).fail(function (data) {
                    _ember['default'].run(function () {
                        model.set('errorMessage', _lfrzLoginHelpersApiHelper['default'].GetMessage(data));
                    });
                });
            }
        }
    });
});