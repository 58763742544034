define('lfrz-login/pods/protected/admin/registrierungen/index/route', ['exports', 'ember', 'lfrz-login/helpers/ApiHelper'], function (exports, _ember, _lfrzLoginHelpersApiHelper) {
    exports['default'] = _ember['default'].Route.extend({
        afterModel: function afterModel(param) {
            this.controllerFor(this.get('routeName')).set('isRefreshing', false);
        },
        actions: {
            selectRegistration: function selectRegistration(param) {
                this.transitionTo('protected.admin.registrierungen.details', param);
            }
        }
    });
});