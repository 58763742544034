define('lfrz-login/pods/protected/index/route', ['exports', 'ember', 'lfrz-login/helpers/ApiHelper', 'lfrz-login/helpers/get-querystring'], function (exports, _ember, _lfrzLoginHelpersApiHelper, _lfrzLoginHelpersGetQuerystring) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model() {
            if (!_ember['default'].$.localStorage.isSet('authToken')) {
                this.transitionTo('login.index');
                return;
            }

            return _lfrzLoginHelpersApiHelper['default'].Get('Projects').then(function (data) {
                //success
                return data;
            }, function () {
                //fail
                return;
            });
        },
        beforeModel: function beforeModel() {
            var _this = this;

            return _lfrzLoginHelpersApiHelper['default'].Get('AcceptDisclaimer').then(function (x) {
                return _this._acceptedDisclaimer = x;
            });
        },
        afterModel: function afterModel(model) {
            this._proj_id = (0, _lfrzLoginHelpersGetQuerystring['default'])('proj_id');
            if (!this._proj_id || !this._acceptedDisclaimer) {
                return;
            }

            for (var i = 0; i < model.length; i++) {
                if (model[i].id == this._proj_id) {
                    this.controllerFor(this.get('routeName')).send('onSelect', model[i]);
                    break;
                }
            }
        },
        setupController: function setupController(controller, model) {
            this._super.apply(this, arguments);

            // for disclaimer
            controller.set('accepted', false);
            controller.set('acceptedDisclaimer', this._acceptedDisclaimer);
            controller.set('redirectToProject', this._proj_id);
        }
    });
});