define("lfrz-login/pods/protected/changepassword/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.12",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 4
            },
            "end": {
              "line": 7,
              "column": 4
            }
          },
          "moduleName": "lfrz-login/pods/protected/changepassword/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createTextNode("Ihr Passwort wurde erfolgreich geändert");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.12",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 8
              },
              "end": {
                "line": 20,
                "column": 66
              }
            },
            "moduleName": "lfrz-login/pods/protected/changepassword/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Passwort ändern");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.12",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 4
            },
            "end": {
              "line": 22,
              "column": 4
            }
          },
          "moduleName": "lfrz-login/pods/protected/changepassword/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createTextNode("\n        Das Passwort muss mindestens 6 unterschiedliche Zeichen enthalten.\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("form");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [5]);
          var element1 = dom.childAt(element0, [5]);
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          morphs[1] = dom.createElementMorph(element0);
          morphs[2] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
          morphs[3] = dom.createMorphAt(element1, 1, 1);
          morphs[4] = dom.createMorphAt(element1, 3, 3);
          morphs[5] = dom.createMorphAt(dom.childAt(element0, [7]), 0, 0);
          morphs[6] = dom.createMorphAt(element0, 9, 9);
          return morphs;
        },
        statements: [["inline", "box-error", [], ["message", ["subexpr", "@mut", [["get", "model.errorMessage", ["loc", [null, [11, 24], [11, 42]]]]], [], []]], ["loc", [null, [11, 4], [11, 44]]]], ["element", "action", ["submit"], ["on", "submit"], ["loc", [null, [12, 10], [12, 41]]]], ["inline", "input-password", [], ["value", ["subexpr", "@mut", [["get", "model.currentPassword", ["loc", [null, [13, 36], [13, 57]]]]], [], []], "placeholder", "aktuelles Passwort"], ["loc", [null, [13, 13], [13, 92]]]], ["inline", "input-password", [], ["value", ["subexpr", "@mut", [["get", "model.password", ["loc", [null, [16, 35], [16, 49]]]]], [], []], "scoreValid", ["subexpr", "@mut", [["get", "model.passwordIsMinimum", ["loc", [null, [16, 61], [16, 84]]]]], [], []], "class", "has-password-strength", "placeholder", "Neues Passwort"], ["loc", [null, [16, 12], [16, 145]]]], ["inline", "password-strength", [], ["password", ["subexpr", "@mut", [["get", "model.password", ["loc", [null, [17, 41], [17, 55]]]]], [], []], "isMinimum", ["subexpr", "@mut", [["get", "model.passwordIsMinimum", ["loc", [null, [17, 66], [17, 89]]]]], [], []]], ["loc", [null, [17, 12], [17, 91]]]], ["inline", "input-password", [], ["value", ["subexpr", "@mut", [["get", "model.passwordConfirm", ["loc", [null, [19, 36], [19, 57]]]]], [], []], "placeholder", "Neues Passwort bestätigen", "confirmPassword", ["subexpr", "@mut", [["get", "model.password", ["loc", [null, [19, 114], [19, 128]]]]], [], []]], ["loc", [null, [19, 13], [19, 130]]]], ["block", "ladda-button", [], ["isSubmitting", ["subexpr", "@mut", [["get", "isSubmitting", ["loc", [null, [20, 37], [20, 49]]]]], [], []]], 0, null, ["loc", [null, [20, 8], [20, 83]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.12",
          "loc": {
            "source": null,
            "start": {
              "line": 25,
              "column": 8
            },
            "end": {
              "line": 25,
              "column": 62
            }
          },
          "moduleName": "lfrz-login/pods/protected/changepassword/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("zurück zur Projektsliste");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.12",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 28,
            "column": 0
          }
        },
        "moduleName": "lfrz-login/pods/protected/changepassword/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "password-change");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h2");
        var el3 = dom.createTextNode("Passwort ändern");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "sub-links");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [2]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(element2, 3, 3);
        morphs[2] = dom.createMorphAt(dom.childAt(element2, [5]), 1, 1);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "title", ["Passwort ändern"], [], ["loc", [null, [1, 0], [1, 27]]]], ["block", "if", [["get", "model.success", ["loc", [null, [5, 10], [5, 23]]]]], [], 0, 1, ["loc", [null, [5, 4], [22, 11]]]], ["block", "link-to", ["protected.index"], [], 2, null, ["loc", [null, [25, 8], [25, 74]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});