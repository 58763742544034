define('lfrz-login/pods/login/register/confirm/index/controller', ['exports', 'ember', 'lfrz-login/helpers/ApiHelper'], function (exports, _ember, _lfrzLoginHelpersApiHelper) {
    exports['default'] = _ember['default'].Controller.extend({
        isSubmitting: false,
        actions: {
            submit: function submit() {
                var _this = this;

                var model = this.get('model');

                if (!model.passwordIsMinimum) {
                    model.set('errorMessage', 'Passwort zu schwach');
                    return;
                } else if (model.password !== model.passwordConfirm) {
                    model.set('errorMessage', 'Passwörter stimmen nicht überein');
                    return;
                }

                if (this.get('isSubmitting')) {
                    return;
                }

                model.set('errorMessage', '');
                this.set('isSubmitting', true);

                _lfrzLoginHelpersApiHelper['default'].Put('Register/' + model.id, model.password).then(function () {
                    _ember['default'].run.later(function () {
                        model.set('requirePassword', false);
                        _this.set('isSubmitting', false);
                    }, 500);
                }, function (data) {
                    _ember['default'].run.later(function () {
                        model.set('errorMessage', _lfrzLoginHelpersApiHelper['default'].GetMessage(data));
                        _this.set('isSubmitting', false);
                    }, 500);
                });
            }
        }
    });
});