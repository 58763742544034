define('lfrz-login/pods/components/input-password/component', ['exports', 'ember', 'lfrz-login/pods/components/input-generic/component'], function (exports, _ember, _lfrzLoginPodsComponentsInputGenericComponent) {
    exports['default'] = _lfrzLoginPodsComponentsInputGenericComponent['default'].extend({
        required: true,
        type: 'password',
        placeholder: 'Passwort',
        scoreValid: true,
        confirmPassword: null,
        onValueChanged: _ember['default'].observer('value', function () {
            if (this.get('confirmPassword')) {
                this.set('isValid', this.get('confirmPassword') === this.get('value'));
            } else {
                this.set('isValid', this.get('scoreValid'));
            }
        }),
        onConfirmChanged: _ember['default'].observer('confirmPassword', function () {
            this.set('isValid', this.get('confirmPassword') === this.get('value'));
        })
    });
});