define('lfrz-login/pods/protected/changepassword/controller', ['exports', 'ember', 'lfrz-login/helpers/ApiHelper'], function (exports, _ember, _lfrzLoginHelpersApiHelper) {
    exports['default'] = _ember['default'].Controller.extend({
        isSubmitting: false,
        actions: {
            submit: function submit() {
                var _this = this;

                var model = this.get('model');

                if (!model.passwordIsMinimum) {
                    model.set('errorMessage', 'Passwort zu schwach');
                    return;
                } else if (model.password !== model.passwordConfirm) {
                    model.set('errorMessage', 'Passwörter stimmen nicht überein');
                    return;
                }

                model.set('errorMessage', '');
                this.set('isSubmitting', true);

                _lfrzLoginHelpersApiHelper['default'].Put('ChangePassword', { newPassword: model.password, currentPassword: model.currentPassword }).then(function () {
                    _ember['default'].run(function () {
                        model.set('success', true);
                        _this.set('isSubmitting', false);
                    });
                }).fail(function (data) {
                    _ember['default'].run(function () {
                        model.set('errorMessage', _lfrzLoginHelpersApiHelper['default'].GetMessage(data));
                        _this.set('isSubmitting', false);
                    });
                });
            }
        }
    });
});