define('lfrz-login/pods/login/register/selbstregistrierung/controller', ['exports', 'ember', 'lfrz-login/helpers/ApiHelper'], function (exports, _ember, _lfrzLoginHelpersApiHelper) {
    exports['default'] = _ember['default'].Controller.extend({
        isSubmitting: false,
        actions: {
            submit: function submit() {
                var _this = this;

                var model = this.get('model');

                if (!model.passwordIsMinimum) {
                    model.set('errorMessage', 'Passwort zu schwach');
                    return;
                } else if (model.password !== model.passwordConfirm) {
                    model.set('errorMessage', 'Passwörter stimmen nicht überein');
                    return;
                }

                if (this.get('isSubmitting')) {
                    return;
                }

                model.set('errorMessage', '');
                this.set('isSubmitting', true);

                var registerData = {
                    firstname: model.firstname,
                    lastname: model.lastname,
                    email: model.email,
                    password: model.password
                };

                if (model.wrapdata) {
                    registerData.id = model.wrapdata.id;
                }

                _lfrzLoginHelpersApiHelper['default'].Post('Register', registerData).then(function () {
                    _ember['default'].run(function () {
                        model.set('success', true);
                        _this.set('isSubmitting', false);
                    });
                }, function (data) {
                    _ember['default'].run(function () {
                        model.set('errorMessage', _lfrzLoginHelpersApiHelper['default'].GetMessage(data));
                        _this.set('isSubmitting', false);
                    });
                });
            }
        }
    });
});