define('lfrz-login/pods/components/project-tile/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        isLoading: _ember['default'].computed('loadingTile', function () {
            return this.get('loadingTile') === this.get('model').id;
        }),
        isHidden: _ember['default'].computed('loadingTile', function () {
            var loadingTile = this.get('loadingTile');
            if (loadingTile == null) {
                return false;
            }

            return loadingTile !== this.get('model').id;
        }),
        actions: {
            select: function select() {
                if (this.get('isHidden') || this.get('isLoading')) {
                    return;
                }

                var model = this.get('model');
                this.sendAction('action', model);

                var target = this.$().find('> div')[0];
                new Spinner({
                    color: '#FFF'
                }).spin(target);
            }
        }
    });
});
/*globals Spinner */